import React from 'react';
import { Box } from 'rebass/styled-components'
import Layout from '../../../templates/Restorer'
import Heading from '../../../modules/Heading'
import AddEdit from '../../../modules/AddEdit'
import VehicleUpload from '../../../modules/VehicleUpload'
import verifyAuth from '../../../system/firebase/requireAuth'
import UploadModal from '../../../modules/UploadModal'

function Edit() {
    return (
        <Layout>
            <Heading size='h3' text='Edit' />
            <Box bg='charcoal' py='lg'>
                <Box variant='container'>
                    <VehicleUpload/>
                    <AddEdit type='vehicle' edit='true'/>
                    <UploadModal />
                </Box>
            </Box>
        </Layout>
    );
}

export default verifyAuth(Edit);
